import React, {useCallback, useState} from "react";
import _ from "lodash";
import Gravatar from "react-gravatar";

import {useWSRoom} from "../../../websocket";
import {useMap} from "../../customHooks/useContextMap";
import useCooTransformation from "./customHooks/useCooTransformation";

import styles from "../../../components/avatar.module.css";
import {CANVAS_HEIGHT, CANVAS_WIDTH} from "./Canvas";


const Pointer = ({wsid, point, avatar, user_name, user_email}) => {
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const x = transformXForCanvas(point.x);
    const y = transformYForCanvas(point.y);

    return (
        //cursor, avatar, name
        <>
            <g transform={`translate(${x} ${y}) scale(-1 1)`}>
                <path
                    style={{fill: "#030104"}}
                    d="M14.337,0.125c-0.117-0.118-0.295-0.156-0.451-0.098L1.576,4.664
                    c-0.171,0.064-0.282,0.23-0.275,0.413c0.007,0.184,0.13,0.342,0.304,0.393l3.086,
                    0.915l-4.566,4.566c-0.167,0.166-0.167,0.436,0,0.602l2.785,2.784c0.166,0.167,
                    0.436,0.167,0.602,0L8.14,9.71l0.851,3.139 c0.049,0.178,0.206,0.305,0.391,
                    0.313s0.354-0.103,0.419-0.274l4.634-12.312C14.494,0.42,14.456,0.243,14.337,
                    0.125z M9.467,11.353 L8.768,8.778C8.729,8.632,8.614,8.516,8.467,8.477S8.163,
                    8.481,8.056,8.588L3.21,13.434l-2.182-2.183L5.799,6.48 c0.106-0.106,0.148-0.26,
                    0.112-0.405C5.874,5.929,5.763,5.813,5.619,5.772L3.061,5.014l10.246-3.859L9.467,
                    11.353z"
                />
            </g>
            {
                !avatar
                ? <foreignObject x={x} y={y} width={40} height={40}>
                    <Gravatar email={user_email} className={styles["react-gravatar"]} size={40}/>
                </foreignObject>
                : <g transform={`translate(${x+5} ${y}) scale(0.5 0.5)`}>
                    <image
                        href={avatar}
                        clipPath="inset(0% round 15px)"
                        width="5rem"
                        height="5rem"
                    />
                </g>
            }

            <g transform={`translate(${x+5} ${y-10})`}>
                <text>{user_name ?? wsid.slice(0, 7)}</text>
            </g>
        </>
    )
}


export const Pointers = ({}) => {
  const {mapId} = useMap();
  const [pointers, setPointers] = useState({});

  const callback = useCallback(({action, data, wsid, user_id, user_name, user_email, avatar}) => {
    if (action === "pointer_move") {
      setPointers(
          {...pointers, [wsid]: {point: data, wsid, user_id, user_name, user_email, avatar}}
      )
    } else if (action === "leave") {
      setPointers(_.omit(pointers, [wsid]));
    } else if (action === "welcome") {
      setPointers({});
    }
  }, [pointers]);

  useWSRoom(`map/${mapId}/pointers`, {callback, key: ["map", mapId], enabled: !_.isUndefined(mapId)});

  return (
      <>
        {
            Object.values(pointers).map(
                ({wsid, point, avatar, user_name, user_email}) =>
                <Pointer
                    key={wsid}
                    wsid={wsid}
                    point={point}
                    avatar={avatar}
                    user_name={user_name}
                    user_email={user_email}
                />
            )
        }
      </>
  )
}
