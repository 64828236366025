import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {MessageBox} from "react-chat-elements";
import {useMutation, useQueries, useQuery, useQueryClient} from "react-query";
import {DateTime as DT} from "luxon";
import {every, isEmpty, isUndefined, range, reverse, sortBy} from "lodash";

import Scrollbar from "../../../components/Scrollbar";
import Avatar from "../../../components/Avatar";
import {useInView} from "react-intersection-observer";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useMap} from "../../customHooks/useContextMap";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useWSRoom} from "../../../websocket";
import {useLocalization} from "../../../customHooks/useContextLocalization";

import "react-chat-elements/dist/main.css";
import "./chat.scss";


const Message = ({message}) => {
    const api = useApiClient();
    const user = useCurrentUser();
    const user_query = useQuery(
        ["user", message.user_id],
        () => api.get(`/api/v1/user/${message.user_id}`),
        {staleTime: Infinity}
    );
    const message_user = user_query.data?.data;

    const isCurrentUserOwner = message.user_id === user.id;

    const avatar = <Avatar
        wrapperClassName="message-avatar"
        imgUrl={message_user?.custom_avatar || message_user?.social_avatar}
        size="24"
        email={message_user?.email}
    />;

    return <div className="message-container">
        {!isCurrentUserOwner && avatar}
        <MessageBox
            position={isCurrentUserOwner ? "right" : "left"}
            type="text"
            notch={false}
            title={isCurrentUserOwner ? "" : message_user?.username}
            text={message.text}
            date={true} /*this prop suddenly required even if dateString declared*/
            dateString={DT.fromISO(message.created, {zone: "utc"}).setZone(DT.local().zoneName)
                .toLocaleString(DT.TIME_24_SIMPLE)
            }
            styles={isCurrentUserOwner ? {backgroundColor: "#9CC6EA"} : {}}
        />
        {isCurrentUserOwner && avatar}
    </div>;
}


export default function () {
    const api = useApiClient();
    const qc = useQueryClient();
    const locale = useLocalization();
    const {mapId} = useMap();
    const lowerRef = useRef();
    const separatorRef = useRef();

    const [message, setMessage] = useState("");
    const [page, setPage] = useState(1);
    const page_size = 25;

    const messages_queries = useQueries(
        range(1, page + 1, 1)
        .map(
            page => {
                return {
                    queryKey: ["message", {room: `chat/${mapId}`, page, page_size}],
                }
            }
        )
    );
    const reversed_messages_queries = reverse(messages_queries);
    const total = messages_queries[0].data?.data?.meta?.total ?? 0;

    const callback = useCallback(async ({action, data, wsid, user_id, avatar}, local_wsid) => {
        await qc.invalidateQueries("message");
    }, [qc]);

    const {send} = useWSRoom(
        `chat/${mapId}`,
        {callback, key: ["chat", mapId], enabled: !isUndefined(mapId)},
    );

    const {mutateAsync: submit} = useMutation(async () => {
        try {
            if (!isUndefined(mapId) && !isEmpty(message)) {
                await api.post(`/api/message`, {room: `chat/${mapId}`, text: message});
                send("message", {text: message});
                await qc.invalidateQueries("message");
                setMessage("");
                lowerRef.current.scrollIntoView();
            }
        } catch (e) {
            console.log(e);
        }
    });

    const {ref: upperRef, inView} = useInView({delay: 100});

    useEffect(() => lowerRef.current.scrollIntoView(), []);  // for the first render

    useEffect(
        () => {
            if (page === 1 && every(messages_queries.map(q => q.isFetched))) {
                lowerRef.current.scrollIntoView();
            }
        },
        [total, page]
    );

    useEffect(() => page > 1 && separatorRef.current.scrollIntoView(), [page]);
    useEffect(
        () => {
            if (inView && total / page_size >= page && every(messages_queries.map(q => q.isFetched))) {
                return setPage(page + 1);
            }
        },
        [inView, page, page_size, total, messages_queries.data]
    );

    let messageDate = "";
    const messagesToRender = reversed_messages_queries.map((q, i) => <Fragment key={i}>
        {q.isFetched
            ? sortBy(q.data?.data?.data ?? [], "created")
                .map(message => {
                    let item = <Message key={message.id} message={message}/>;
                    const convertedCurrentDate = DT.fromISO(message.created, {zone: "utc"})
                        .toLocaleString(DT.DATE_FULL, {locale: locale.get.langCode.toLowerCase()});
                    if (convertedCurrentDate !== messageDate) {
                        messageDate = convertedCurrentDate;
                        return <Fragment key={`${message.id}-message`}>
                            <div className="date-header">
                                {convertedCurrentDate}
                            </div>
                            {item}
                        </Fragment>;
                    }
                    return item;
                })
            : <div className="placeholder"/>
        }
        {i === 0 && <div className="separator" ref={separatorRef}/>}
    </Fragment>);

    const sendMessage = e => e.key === "Enter" && !e.ctrlKey && submit();

    return <div className="chat">
        <div className="tabs-container">
            <span className="tab-active">{locale.get.studio.chat.main}</span>
            <span className="tab">{locale.get.studio.chat.tech}</span>
        </div>
        <div className="messages-wrapper">
            <div className="messages">
                <Scrollbar
                    autohide={false}
                    classNamePrefix="my-maps-"
                    style={{height: "100%"}}
                    viewStyle={{overflow: "auto", marginBottom: "0"}}
                >
                    <div className="upper" ref={upperRef}/>
                    {messagesToRender}
                    <div className="lower" ref={lowerRef}/>
                </Scrollbar>
            </div>
            <input
                className="current-message-field"
                type="text"
                placeholder={locale.get.studio.chat.inputPlaceholder}
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyDown={sendMessage}
            />
        </div>
    </div>;
}
